import * as React from 'react';
import {useNavigate} from "react-router-dom";

const PaymentConfirmation = () => {

  const navigate = useNavigate();

  return (
    <div className="confimationPage" >
      <h1 className='confirmationHeader'>YOU ARE A<br/>
      <span className='enormousText'>BEAST</span><br/>
      AND WE ARE <br/>
      <span className='enormousText'>STOKED</span><br/>
      TO HAVE YOU!!!!</h1>

      <button className={'mainButton marginBottom24px'} onClick={() => navigate('/dashboard')}>Get Started Selling Now</button>

      <div className='confirmation-nextStepSection'>
        


        <div className='confirmation-nextStepDiv'>
          <div className='width100'>
            <h2>Here is what will happen in the next 24 hours:</h2> 
          </div>


          <div className='confirmation-nextStep-container'>
          <div className='confirmation-nextStep-item'>
              <h3>#1: You will get an invite to our private Slack Group</h3>
              <p>Slack is the fastest way to get in touch with our team for support, reviewing emails, and asking questions. Plus you get to meet people just like you! </p>
          </div>

          <div className='confirmation-nextStep-item'>
              <h3>#2: Our founders will reach out to you to schedule your first call.</h3>
              <p>We will be sending you an email with a link to our Calendar to schedule your first call. </p>
          </div>

          <div className='confirmation-nextStep-item'>
              <h3>#3: Write your first incredible email sequence</h3>
              <p>Dive in! Our tool will walk you through what you need to get started. Then in your call, we can help you make it 2x better!</p>
          </div>
          </div>
          
        </div>
        
      </div>
      
      
    </div>
  )
}

export default PaymentConfirmation;
