import * as React from "react";
import { useState, useEffect } from "react";
import SequenceTimeSelect from "../components/SequenceTimeSelect";
import EmailPersonalizer from "../components/EmailPersonalizer";
import SSCButton from "../components/SSCButton";
import { Link } from "react-router-dom";
import { GET, POST } from "../util/ApiUtils";
import { Person } from "../types/Person";
import { EmailOption, SequenceOption } from "../types/SequenceOption";
import { AnimatePresence, motion } from "framer-motion";

const STEP_OPTION = {
  TIME: "time",
  PERSONALIZE: "personalize",
  SEND_ANOTHER: "sendAnother",
} as const;

type STEP_KEYS = keyof typeof STEP_OPTION;
type STEP = (typeof STEP_OPTION)[STEP_KEYS];

const PersonalizationPage = () => {
  const [step, setStep] = useState<STEP>(STEP_OPTION.TIME);

  //This is for Framer Motion
  const [isVisible, setIsVisible] = useState(true);

  const [lead, setLead] = useState<Person>();
  const [sequenceOptions, setSequenceOptions] = useState<SequenceOption[]>([]);
  const [selectedSequence, setSelectedSequence] = useState<SequenceOption>();
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number>(0);

  const [emailsToSave, setEmailsToSave] = useState<EmailOption[]>([]);

  const [sendNow, setSendNow] = useState<boolean>(false);
  const [sendDate, setSendDate] = useState<string>("");

  const [isSending, setIsSending] = useState<boolean>(false);

  const load = async () => {
    const loadResp = await GET({
      endpoint: "campaigns/loadSequencePersonalizer",
    });

    setLead(loadResp?.data?.lead);
    setSequenceOptions(loadResp?.data?.sequenceOptions);
    setSelectedSequence(loadResp?.data?.sequenceOptions?.at(0));
  };

  const handleRightNow = () => {
    setSendNow(true);
    setStep(STEP_OPTION.PERSONALIZE);
  };

  const handleScheduleForLater = (date: string) => {
    setSendDate(date);
    setStep(STEP_OPTION.PERSONALIZE);
  };

  const handleEditEmail = (update: object) => {
    const selectedEmail = selectedSequence?.emails?.at(selectedEmailIndex);
    const updatedEmail = { ...selectedEmail, ...update };
    const updatedEmails = selectedSequence?.emails?.map((email, index) =>
      index === selectedEmailIndex ? updatedEmail : email,
    );

    const updatedSequence = { ...selectedSequence, emails: updatedEmails };
    const updatedSequences = sequenceOptions?.map((sequence) =>
      sequence?.id === selectedSequence?.id ? updatedSequence : sequence,
    );

    const emailsToSaveIndex = emailsToSave?.findIndex(
      (email) => email?.id === selectedEmail?.id,
    );
    if (emailsToSaveIndex >= 0) {
      const updatedEmailsToSave = [...emailsToSave];
      updatedEmailsToSave?.splice(
        emailsToSaveIndex,
        1,
        updatedEmail as EmailOption,
      );
      setEmailsToSave(updatedEmailsToSave);
    } else {
      const updatedEmailsToSave = [...emailsToSave, updatedEmail];
      setEmailsToSave(updatedEmailsToSave as EmailOption[]);
    }

    setSequenceOptions(updatedSequences as SequenceOption[]);
    setSelectedSequence(updatedSequence as SequenceOption);
  };

  const handleSendEmail = async (paragraphText: string, linkText: string) => {
    setIsSending(true);

    const sendResponse = await POST({
      endpoint: "leads/addLeadToSequence",
      body: {
        userInfoId: lead?.id,
        emailCampaignId: selectedSequence?.id,
        sendNow,
        sendDate,
        emails: emailsToSave,
        paragraphText,
        linkText,
      },
    });

    setEmailsToSave([]);
    setStep(STEP_OPTION.SEND_ANOTHER);
    setIsSending(false);
  };

  // This is stuff for Framer Motion
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 0.3,
      },
    },
  };

  const itemA = {
    hidden: { scale: 0 },
    show: { scale: 1 },
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 3500); // Adjust the time in milliseconds (e.g., 5000 for 5 seconds)

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);
  // Ends stuff for Framer Motion

  if (step === STEP_OPTION.TIME) {
    return (
      <>
        {isVisible && (
          <AnimatePresence>
            <motion.div
              key="motion"
              className="blackBackground"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1, delay: 2 }}
            >
              <motion.div variants={container} initial="hidden" animate="show">
                <motion.h1
                  className="getStoked textColor--white"
                  variants={itemA}
                >
                  Lets.
                </motion.h1>
                <motion.h1
                  className="getStoked textColor--white"
                  variants={itemA}
                >
                  Freaking.
                </motion.h1>
                <motion.h1
                  className="getStoked textColor--white"
                  variants={itemA}
                >
                  DO IT!!!
                </motion.h1>

                <motion.p className="textColor--white" variants={itemA}>
                  You are a beast!
                </motion.p>
              </motion.div>
            </motion.div>
          </AnimatePresence>
        )}

        <SequenceTimeSelect
          error=""
          onRightNow={handleRightNow}
          onScheduleForLater={handleScheduleForLater}
        />
      </>
    );
  } else if (step === STEP_OPTION.PERSONALIZE) {
    return (
      <EmailPersonalizer
        load={load}
        lead={lead}
        sequenceOptions={sequenceOptions}
        selectedSequence={selectedSequence}
        setSelectedSequence={setSelectedSequence}
        selectedEmailIndex={selectedEmailIndex}
        setSelectedEmailIndex={setSelectedEmailIndex}
        handleEditEmail={handleEditEmail}
        onSendEmail={handleSendEmail}
        isSending={isSending}
      />
    );
  } else if (step === STEP_OPTION.SEND_ANOTHER) {
    return (
      // todo
      <div className="container--maxWidth1100px container--maxWidth1100px--paddingTop64px centerText">
        <h1>Woo hoo do you want to send another?</h1>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
        >
          <SSCButton
            text="Send Another"
            onClick={() => setStep(STEP_OPTION.PERSONALIZE)}
            className="mainButton"
          />

          <Link to="/dashboard" className="linkInButton">
            <SSCButton text="I'm a quitter" className="mainButton opacity50" />
          </Link>
        </div>
      </div>
    );
  }

  return <></>;
};

export default PersonalizationPage;
