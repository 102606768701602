import * as React from "react";

interface CheckboxProps {
  id: string;
  label?: string;
  checked: boolean;
  onClick: () => void;
}

const SSCCheckbox = ({
  id,
  label = "",
  checked = false,
  onClick,
}: CheckboxProps) => {
  const handleChange = () => {
    onClick();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* todo: Rob styles */}
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleChange}
        style={{
          accentColor: "#000",
          borderColor: "#000",
          transform: "scale(1.5)",
        }}
      />
      <label style={{ fontWeight: "bold", marginLeft: 5 }} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default SSCCheckbox;
