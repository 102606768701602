import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../auth/AuthProvider";
import { googleLogout } from "@react-oauth/google";
import { POST } from "../util/ApiUtils";

const Header = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const goToStripeDashboard = async () => {
    const resp = await POST({ endpoint: "payment/sendToStripe" });

    if (resp?.data?.redirect) {
      window.location.href = resp?.data?.redirect;
    }
  };

  const logOut = async () => {
    googleLogout();
    await POST({ endpoint: "auth/logout" });
    signOut(() => navigate("/login"));
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getHeaderOptions = () => {
    // logged in user options
    if (user) {
      if (location?.pathname === "/paymentOptions") {
        return (
          <>
            <a className="linkInNav" onClick={logOut}>
              Logout
            </a>
          </>
        );
      }

      return (
        <>
          <Link to="/dashboard" className="linkInNav">
            Dashboard
          </Link>
          <Link to="/newSequence" className="linkInNav">
            Sequences
          </Link>
          <Link to="/people" className="linkInNav">
            People
          </Link>
          <a className="linkInNav" onClick={goToStripeDashboard}>
            Profile
          </a>
          <a className="linkInNav" onClick={logOut}>
            Logout
          </a>
        </>
      );
    } else {
      // no user logged in
      return (
        <>
          <Link to="/login" className="linkInNav">
            Login
          </Link>
          <Link to="/login" className="linkInNav">
            Create Account
          </Link>
        </>
      );
    }
  };

  return (
    <>
      <div className="nav">
        <p className="logo">Sales Software Co</p>
        <div className="links">{getHeaderOptions()}</div>

        <div className="nav-hamburgerButton">
          <button className="menuButton" onClick={toggleMenu}>
            Menu
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="nav-slideOut">
          <button className="menuButton_close" onClick={toggleMenu}>
            Close
          </button>

          <div className="links-inSlideOut">
            <Link to="/dashboard" className="linkInNav" onClick={toggleMenu}>
              Dashboard
            </Link>
            <Link to="/newSequence" className="linkInNav" onClick={toggleMenu}>
              Sequences
            </Link>
            <Link to="/people" className="linkInNav" onClick={toggleMenu}>
              People
            </Link>
            <a className="linkInNav" onClick={goToStripeDashboard}>
              Profile
            </a>
            <a className="linkInNav" onClick={logOut}>
              Logout
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
