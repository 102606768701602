import React, { ChangeEvent, useRef, useState } from "react";
import Table from "../components/Table";
import ReactModal from "react-modal";
import SSCButton from "../components/SSCButton";
import { Person } from "../types/Person";
import { usePagination } from "../hooks/usePagination";
import { MULTIPARTPOST, POST } from "../util/ApiUtils";
import CsvHeaderSelect from "../components/CsvHeaderSelect";

const TAB_OPTION = {
  CURRENT: "current",
  PAST: "past",
} as const;

type TAB_KEYS = keyof typeof TAB_OPTION;
type TAB = (typeof TAB_OPTION)[TAB_KEYS];

const People = () => {
  const [activeTab, setActiveTab] = useState<TAB>(TAB_OPTION.CURRENT);
  const [page, setPage] = useState(1);

  const [selectedPerson, setSelectedPerson] = useState<Person>({} as Person);
  const [showEditPersonModal, setShowEditPersonModal] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<any[]>([]);
  const [selectHeaders, setSelectHeaders] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { list, numPages, reload } = usePagination<Person>({
    page,
    endpoint: "leads/loadPeople",
    data: { isPast: activeTab === TAB_OPTION.PAST },
    dependencies: [activeTab],
  });

  const handleTabClick = (tab: TAB) => {
    setActiveTab(tab);
  };

  const openAddPersonModal = () => {
    setShowEditPersonModal(true);
  };

  const openDeleteModal = (person: Person) => {
    setSelectedPerson(person);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedPerson({} as Person);
    setShowDeleteModal(false);
  };

  const submitDelete = async () => {
    const deleteResp = await POST({
      endpoint: "leads/deleteLead",
      body: { id: selectedPerson?.id },
    });

    closeDeleteModal();
    reload();
  };

  const openEditModal = (person: Person) => {
    setSelectedPerson(person);
    setShowEditPersonModal(true);
  };

  const closeEditModal = () => {
    setSelectedPerson({} as Person);
    setShowEditPersonModal(false);
  };

  const submitEdit = async () => {
    const saveData = await POST({
      endpoint: "leads/saveLead",
      body: selectedPerson,
    });

    closeEditModal();
    reload();
  };

  const handleInputChange = (update: Partial<Person>) => {
    setSelectedPerson({ ...selectedPerson, ...update });
  };

  const selectFile = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files || files[0] === null) return;

    setCsvFile(files[0]);

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e?.target?.result;
      if (!text || typeof text !== "string") {
        return;
      }

      const h = text
        .slice(0, text.indexOf("\n"))
        .split(",")
        .map((header) => header.trim());
      setHeaders(h);

      const numHeaders = h.length;
      const defaultSelectedHeaders: any[] = [];
      for (let i = 0; i < numHeaders; i++) {
        const defaultHeader = {
          index: i,
          header: "email",
        };

        defaultSelectedHeaders.push(defaultHeader);
      }

      setSelectedHeaders(defaultSelectedHeaders);
      setSelectHeaders(true);
    };

    reader.readAsText(files[0]);
  };

  const selectHeader = (h: string, index: number) => {
    const headerIndex = selectedHeaders.findIndex(
      (selectedHeader) => selectedHeader?.index === index
    );

    const selectedHeader = {
      index,
      header: h,
    };

    if (headerIndex < 0) {
      setSelectedHeaders((prevState) => [...prevState, selectedHeader]);
    } else {
      setSelectedHeaders(
        selectedHeaders.map((header) =>
          header?.index === index ? selectedHeader : header
        )
      );
    }
  };

  const uploadCsv = async () => {
    if (!csvFile) {
      return;
    }

    let data = new FormData();
    data.append("file", csvFile);
    data.append("headers", JSON.stringify(selectedHeaders));

    const fileUploadResp = await MULTIPARTPOST({
      endpoint: "leads/uploadCsv",
      body: data,
    });

    if (fileUploadResp?.data?.error) {
      // todo: error
    } else {
      setSelectHeaders(false);
      reload();
    }
  };

  if (selectHeaders) {
    return (
      <CsvHeaderSelect
        headers={headers}
        onSelectHeader={selectHeader}
        onUpload={uploadCsv}
      />
    );
  }

  return (
    <>
      <div className="tableContainer">
        <div className="peoplePage_pageLabel">
          <div className="peopleTabs">
            <h2
              className={`${activeTab === TAB_OPTION.CURRENT ? "" : "active"}`}
              onClick={() => handleTabClick(TAB_OPTION.CURRENT)}
            >
              Current People
            </h2>
            <h2
              className={`${activeTab === TAB_OPTION.PAST ? "" : "active"}`}
              onClick={() => handleTabClick(TAB_OPTION.PAST)}
            >
              Past People
            </h2>
          </div>

          <div className="peoplePage_buttonsDiv">
            <div>
              <a
                className="csvLink"
                onClick={() => fileInputRef?.current?.click()}
              >
                Upload CSV
              </a>
              <input
                type="file"
                accept="text/csv"
                hidden
                ref={fileInputRef}
                onChange={selectFile}
              />

              <button
                className="mainButton"
                onClick={() => openAddPersonModal()}
              >
                + Add Person
              </button>
            </div>
          </div>
        </div>

        <div className="tab-content">
          <div className="peoplePage_tableDiv">
            <Table
              people={list}
              onEdit={(person) => openEditModal(person)}
              onDelete={(person) => openDeleteModal(person)}
            />
          </div>

          <div className="pagination_buttons">
            <SSCButton
              text="Previous"
              onClick={() => setPage(page - 1)}
              className="mainButton"
              disabled={page <= 1}
            />
            <span>Page {page}</span>
            <SSCButton
              text="Next"
              onClick={() => setPage(page + 1)}
              className="mainButton"
              disabled={page >= numPages}
            />
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={showEditPersonModal}
        onRequestClose={() => setShowEditPersonModal(false)}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          },
          content: {
            width: "480px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            paddingRight: 32,
            paddingLeft: 32,
          },
        }}
        ariaHideApp={false}
      >
        <h2>Add Person</h2>

        <form>
          <div className="form_sideBySide">
            <div className="marginBottom24px marginRight8px">
              <p className="textLabel">First Name</p>
              <input
                type="text"
                className="inputBox inputBox_width100"
                value={selectedPerson?.firstName || ""}
                onChange={(e) =>
                  handleInputChange({ firstName: e.target.value })
                }
              />
            </div>
            <div className="marginLeft8px marginBottom24px">
              <p className="textLabel">Last Name</p>
              <input
                type="text"
                className="inputBox inputBox_width100"
                value={selectedPerson?.lastName || ""}
                onChange={(e) =>
                  handleInputChange({ lastName: e.target.value })
                }
              />
            </div>
          </div>
          <div className="marginBottom24px">
            <p className="textLabel">Company Name</p>
            <input
              type="text"
              className="inputBox inputBox_width100"
              value={selectedPerson?.companyName || ""}
              onChange={(e) =>
                handleInputChange({ companyName: e.target.value })
              }
            />
          </div>
          <div className="marginBottom24px">
            <p className="textLabel">Email</p>
            <input
              type="text"
              className="inputBox inputBox_width100"
              value={selectedPerson?.email || ""}
              onChange={(e) => handleInputChange({ email: e.target.value })}
            />
          </div>

          <div className="marginBottom24px">
            <p className="textLabel">Title</p>
            <input
              type="text"
              className="inputBox inputBox_width100"
              value={selectedPerson?.title || ""}
              onChange={(e) => handleInputChange({ title: e.target.value })}
            />
          </div>
          <div className="marginBottom24px">
            <p className="textLabel">Website</p>
            <input
              type="text"
              className="inputBox inputBox_width100"
              value={selectedPerson?.website || ""}
              onChange={(e) => handleInputChange({ website: e.target.value })}
            />
          </div>

          <div className="marginBottom24px">
            <p className="textLabel">LinkedIn</p>
            <input
              type="text"
              className="inputBox inputBox_width100"
              value={selectedPerson?.linkedIn || ""}
              onChange={(e) => handleInputChange({ linkedIn: e.target.value })}
            />
          </div>
        </form>
        <div className="modal__buttonContainer--rightLeft">
          <button
            className="mainButton opacity50 marginRight12px"
            onClick={closeEditModal}
          >
            Cancel
          </button>
          <SSCButton
            text={selectedPerson ? "Save" : "Add Person"}
            onClick={submitEdit}
            className="mainButton"
          />
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            borderRadius: "0px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          },
          content: {
            width: "480px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            paddingRight: 32,
            paddingLeft: 32,
          },
        }}
        ariaHideApp={false}
      >
        <h2>Are you sure you want to delete this?</h2>
        <p className="marginBottom24px">This is not something you can undo.</p>

        <div className="modal__buttonContainer--rightLeft">
          <button
            className="mainButton opacity50 marginRight12px"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <SSCButton
            text="Delete Person"
            onClick={submitDelete}
            className="mainButton"
          />
        </div>
      </ReactModal>
    </>
  );
};

export default People;
