import SSCButton from "./SSCButton";
import DatePicker from "react-datepicker";
import * as React from "react";
import { useState } from "react";
import { getStartDate } from "../util/DateUtil";

interface TimeSelectProps {
  error: string;
  onRightNow: () => void;
  isLoading?: boolean;
  onScheduleForLater: (date: string) => void;
  isScheduling?: boolean;
}

const getMinDate = () => {
  const date = new Date();
  const hours = date.getHours();

  if (hours > 20) {
    const tomorrow = new Date();
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
  }

  return date;
};

const minHours = new Date();
minHours.setHours(5);

const maxHours = new Date();
maxHours.setHours(18);

const SequenceTimeSelect = ({
  error,
  onRightNow,
  isLoading = false,
  onScheduleForLater,
  isScheduling = false,
}: TimeSelectProps) => {
  const [startDate, setStartDate] = useState<Date | null>(getMinDate());
  const [startTime, setStartTime] = useState<number>(new Date().getHours() + 1);

  const [dateError, setDateError] = useState<string>("");

  const getTimeOptions = () => {
    const date = new Date();

    const sameDay =
      date.toLocaleDateString() === startDate?.toLocaleDateString();

    const timeOptions = [];
    if (sameDay) {
      // options after now
      const hours = date.getHours() + 1;
      if (hours <= 12) {
        for (let i = hours; i < 12; ++i) {
          timeOptions.push(
            <option key={i} value={i}>
              {i}:00 AM
            </option>,
          );
        }

        timeOptions.push(
          <option key={12} value={12}>
            12:00 Noon
          </option>,
        );
      }

      if (hours < 20) {
        const afterNoonHours = Math.max(1, hours - 12);
        for (let i = afterNoonHours; i < 9; ++i) {
          timeOptions.push(
            <option key={i + 12} value={i + 12}>
              {i}:00 PM
            </option>,
          );
        }
      }

      if (timeOptions?.length === 0) {
        timeOptions.push(
          <option key={-1} disabled>
            No times
          </option>,
        );
      }
    } else {
      // all options
      for (let i = 6; i < 12; ++i) {
        timeOptions.push(
          <option key={i} value={i}>
            {i}:00 AM
          </option>,
        );
      }

      timeOptions.push(
        <option key={12} value={12}>
          12:00 Noon
        </option>,
      );

      for (let i = 1; i < 9; ++i) {
        timeOptions.push(
          <option key={i + 12} value={i + 12}>
            {i}:00 PM
          </option>,
        );
      }
    }

    return timeOptions;
  };

  const handleScheduleForLater = () => {
    if (!startDate) {
      setDateError("No start date selected");
      return;
    }

    if (!startTime) {
      setDateError("No start time selected");
      return;
    }

    setDateError("");

    const month = startDate.getUTCMonth() + 1;
    const day = startDate.getDate();
    const year = startDate.getUTCFullYear();

    const date = getStartDate(month, day, year, startTime);
    onScheduleForLater(date);
  };

  return (
    <div className="container--maxWidth1100px container--maxWidth1100px--paddingTop64px centerText">
      <h1>When do you want us to send these emails?</h1>

      {error !== "" && (
        <div className="container--maxWidth1100px paddingBottom24px">
          <div className="dashboard-noCampaigns">
            <p>{error}</p>
          </div>
        </div>
      )}

      <div className="addPeople_container">
        <div className="emailContainer">
          <div className="width100 marginOnMobile ">
            <p className="noMargin">Option #1:</p>
            <h3 className="noMargin">Send as I finish</h3>
          </div>

          <SSCButton
            text="As I Finish"
            onClick={onRightNow}
            isLoading={isLoading}
            className="mainButton"
          />
        </div>

        <div className="emailContainer">
          <div className="width100 marginBottom24px">
            <p className="noMargin">Option #2:</p>
            <h3 className="noMargin">Schedule them to send at the same time</h3>
          </div>

          <div className="addPeople_sideBySide marginBottom24px">
            <div className="addPeople_dropdownElement marginBottom24px">
              <label className="marginBottom8px">Day</label>
              <div className="">
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  minDate={getMinDate()}
                />
              </div>
            </div>

            <div className="addPeople_dropdownElement">
              <label>Time</label>
              <select
                className="inputBox width100Percent"
                onChange={(e) => setStartTime(Number(e?.target?.value))}
              >
                {getTimeOptions()}
              </select>
            </div>
          </div>

          <SSCButton
            text="Schedule for Later"
            onClick={handleScheduleForLater}
            isLoading={isScheduling}
            className="mainButton"
          />
        </div>
      </div>
    </div>
  );
};

export default SequenceTimeSelect;
