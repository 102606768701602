import React, { useState, FC } from "react";
import { SequenceOption } from "../types/SequenceOption";

interface SequenceDropdownProps {
  options: SequenceOption[];
  selectedOption?: SequenceOption;
  onOptionSelected: (option: SequenceOption) => void;
}

const SequenceDropdown: FC<SequenceDropdownProps> = ({
  options,
  selectedOption,
  onOptionSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: SequenceOption) => {
    onOptionSelected(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown-header" onClick={handleToggle}>
        <div>
          <label className="fieldLabel">Sequence Name</label>
          <h2 className="sequenceSelectText">
            {selectedOption ? selectedOption?.name : "Select an option"}
          </h2>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 320 512"
        >
          <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
        </svg>
      </div>
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option) => (
            <li
              key={option?.id}
              onClick={() => handleSelect(option)}
              className="sequenceList_item"
            >
              <h3 className="sequenceList_header">{option?.name}</h3>
              {/*<p className="sequenceList_subHeader">*/}
              {/*  1,502 People | 55% Opens | 15% Replies{" "}*/}
              {/*</p>*/}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SequenceDropdown;
