import * as React from "react";

const Help = () => {
  return (
    <>
      <p>Great! Can't wait to help</p>
      <p>Here is what we want to do. Selling people on the phone is hard for a lot of reasons. Most of all, people don’t answer.
        We have found email to be a great way to sell.</p>
      <p>But, you can’t just send one email. You need to try a few times. Our software automates that: you put names in, we send the emails.</p>
    </>
  )
}

export default Help;
