import * as React from "react";
import { useState } from "react";
// @ts-ignore
import { ReactComponent as Arrow } from "../images/arrow.svg";
import { EmailCampaignTemplate } from "../types/EmailCampaignTemplate";
import { Link } from "react-router-dom";

type CampaignProps = {
  campaign: EmailCampaignTemplate;
  sendSequence: () => void;
  makeCopy: () => void;
};

const CampaignTemplate = ({
  campaign,
  sendSequence,
  makeCopy,
}: CampaignProps) => {
  const [showEmails, setShowEmails] = useState(false);
  const [showButtonText, setShowButtonText] = useState(false);
  const [carouselPosition, setCarouselPosition] = useState(0);

  const toggleShowEmail = () => {
    setShowEmails(!showEmails);
    setShowButtonText((prevButtonText) => !prevButtonText);
  };

  const nextEmail = () => {
    setCarouselPosition((prevIndex) =>
      prevIndex === campaign?.emails?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevEmail = () => {
    setCarouselPosition((prevIndex) =>
      prevIndex === 0 ? campaign?.emails?.length - 1 : prevIndex - 1
    );
  };

  const currentEmail = campaign?.emails?.at(carouselPosition);

  return (
    <div className="box--border--black dashboardBox sequencesChange positionRelative">
      <div className="sequence_box_topDiv">
        <div className="dashboardBoxSection--1">
          <p className="labelInBox">Name</p>
          <p className="boldTextInBox">{campaign?.name}</p>
        </div>
        {/* In the Sequence page, this is the middle section of data. */}

        <div className="sequence_dataDiv">
          <div>
            <p className="labelInBox centerText">Emails</p>
            <p className="boldTextInBox centerText">{campaign?.numEmails}</p>
          </div>
          <div>
            <p className="labelInBox centerText">Total Sends</p>
            <p className="boldTextInBox centerText">{campaign?.totalSends}</p>
          </div>
          <div>
            <p className="labelInBox centerText">Open Rate</p>
            <p className="boldTextInBox centerText">{campaign?.openRate}%</p>
          </div>
          <div>
            <p className="labelInBox centerText">Reply Rate</p>
            <p className="boldTextInBox centerText">{campaign?.replyRate}%</p>
          </div>
        </div>

        <div></div>

        <div className="dashboardBoxSection--3">
          {/* Rob did this! */}
          <Link
            to={`/campaign/${campaign.id}`}
            className="mainButton marginBottom16px"
          >
            Edit
          </Link>

          <button
            className="mainButton mainButton--outlined width100"
            onClick={makeCopy}
          >
            Make a copy
          </button>
        </div>
      </div>

      <div className="">
        {showEmails && (
          <div className="pickCampaign-grid ">
            <div className="grid-side">
              <div className="pickCampaign-arrowDiv" onClick={prevEmail}>
                <Arrow />
              </div>
            </div>

            <div className="pickCampaign-email">
              <h3>Email #{carouselPosition + 1}</h3>

              <h4>Subject Line</h4>
              <p>{currentEmail?.subject}</p>

              <h4>Message</h4>
              <p>{currentEmail?.body}</p>
            </div>

            <div className="grid-side">
              <div className="pickCampaign-arrowDiv" onClick={nextEmail}>
                <Arrow className="flipArrow" />
              </div>
            </div>
          </div>
        )}

        {campaign?.emails?.length > 0 && (
          <button className="showEmailsLink" onClick={toggleShowEmail}>
            {showButtonText ? "Hide Emails" : "Show Emails"}
          </button>
        )}
      </div>
    </div>
  );
};

export default CampaignTemplate;
