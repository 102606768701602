import * as React from "react";

interface ProgressBarProps {
  num: number;
  total: number;
}

const ProgressBar = ({num, total}: ProgressBarProps) => {

  const completed = (num / total) * 100;

  const container = {
    height: 24,
    width: '100%',
    backgroundColor: "#bbb",
    margin: 10
  }

  const fill = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '#000',
    borderRadius: 'inherit'
  }

  const label = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold', 
  }

  return (
   <div style={container}>
     <div style={fill}>
       <span className="fontSize--20px" style={label}>{`${num}/${total}`}</span>
     </div>
   </div>
  )
}

export default ProgressBar;
