export const getStartDate = (
  month: number,
  day: number,
  year: number,
  startTime: number,
) => {
  return `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}T${startTime < 10 ? "0" : ""}${startTime}:00:00`;
};
