export const getBaseUrl = () => {
  const isTest = window?.location?.origin?.includes('app.test.salessoftwareco.com');
  if(isTest) {
    return 'https://app.test.salessoftwareco.com';
  }

  const isProd = window?.location?.origin?.includes('app.salessoftwareco.com');
  if(isProd) {
    return 'https://app.salessoftwareco.com';
  }

  return 'http://localhost:8080';
}

export const GET = async ({endpoint = '', params = {}}) => {
  const paramStr = Object.keys(params)
    .map(key => [`${key}=${params[key]}`])
    .join('&');

  const uri = `/api/${endpoint}?${paramStr}`;

  const payload = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return handleResponse({uri, payload});
};

export const POST = async ({endpoint = '', body = {}}) => {
  let uri = `/api/${endpoint}`;

  const payload = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  return handleResponse({uri, payload});
};

export const MULTIPARTPOST = async ({endpoint = '', body = new FormData()}) => {
  let uri = `/api/${endpoint}`;

  const payload = {
    method: 'POST',
    body
  }

  return handleResponse({uri, payload});
}

const handleResponse = async ({uri, payload}) => {
  const response = await fetch(uri, payload);
  const json = await response.json();

  return new Promise(resolve => {
    const {path} = json;
    if (path) {
      if (path.includes('Login')) {
        // console.log('user needs to login'); // todo
      }
    } else {
      resolve(json);
    }
  });
};
