import * as React from "react";
import { useEffect } from "react";
import { GET, getBaseUrl } from "../util/ApiUtils";
import { useAuth } from "../auth/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getCurrentSession();
  }, []);

  const getCurrentSession = async () => {
    const sessionResp = await GET({ endpoint: "auth/currentSession" });
    const user = sessionResp?.data?.user;

    if (user) {
      auth.signIn(user, () => {
        if (sessionResp?.data?.redirect) {
          navigate(sessionResp?.data?.redirect);
          return;
        }

        // @ts-ignore
        const from = location?.state?.from?.pathname || "/dashboard";

        if (from.includes("confirmation")) {
          // @ts-ignore
          navigate(`${from}${location?.state?.from?.search}`, {
            replace: true,
          });
        } else {
          navigate(from, { replace: true });
        }
      });
    }
  };

  const login = () => {
    window.location.href = `${getBaseUrl()}/oauth2/authorize/google?redirect_uri=${window
      ?.location?.origin}/oauth2/redirect`;
  };

  return (
    <div className="heroDiv positionRelative">
      <div className="textContainer">
        <div>
          <h1 className="mainH1 centerText">
            Lets get you logged in with Gmail
          </h1>
        </div>
        <div className="centerText flex-justifyCenter">
          <button className="mainButton loginButton" onClick={login}>
            <div className="loginButtonDiv">
              <img
                src="https://developers.google.com/static/identity/images/g-logo.png"
                alt="Google Sign-In"
              />
            </div>
            SIGN IN WITH GOOGLE
          </button>
        </div>
      </div>
      <div className="googleDisclaimer">
        <p className="centerText">
          Sales Software Co's use and transfer to any other app of information
          received from Google APIs will adhere to the{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </p>
      </div>
    </div>
  );
};

export default Login;
