import * as React from "react";

interface ButtonProps {
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  className: string;
}

const SSCButton = ({
  text,
  onClick,
  isLoading = false,
  disabled,
  className,
}: ButtonProps) => {
  let style = {
    opacity: "",
  };

  if (disabled && !isLoading) {
    style.opacity = "50%";
  }

  return (
    <button
      className={className}
      onClick={onClick}
      style={style}
      disabled={isLoading || disabled}
    >
      {isLoading && <div className="loading" />}
      {text}
    </button>
  );
};

export default SSCButton;
