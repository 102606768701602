import * as React from "react";
import { useEffect, useState } from "react";
import { GET, POST } from "../util/ApiUtils";
import { useLocation, useNavigate } from "react-router-dom";
import SSCButton from "../components/SSCButton";
import CampaignTemplate from "../components/CampaignTemplate";
import { EmailCampaignTemplate } from "../types/EmailCampaignTemplate";

const FLOW = {
  NEW: "new",
  COPY: "copy",
};

const STEP = {
  NAME: "name",
  NUMBER: "number",
  CAMPAIGN: "campaign",
};

const NewSequence = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [flow, setFlow] = useState<string>(FLOW.COPY);
  const [step, setStep] = useState<string>(STEP.CAMPAIGN);
  const [name, setName] = useState<string>("");
  const [number, setNumber] = useState<number>(1);
  const [campaigns, setCampaigns] = useState<EmailCampaignTemplate[]>([]);
  const [selectedCampaign, setSelectedCampaign] =
    useState<EmailCampaignTemplate>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (state?.step === STEP.NAME) {
      setFlow(FLOW.NEW);
      setStep(STEP.NAME);
    }
  }, [state]);

  const load = async () => {
    const resp = await GET({ endpoint: "campaigns/loadCopiableCampaigns" });
    setCampaigns(resp?.data?.emailCampaigns);
  };

  const nextStep = async () => {
    if (flow === FLOW.NEW) {
      // new campaign
      if (step === STEP.NAME) {
        // send to step to pick number of emails
        if (name) {
          setStep(STEP.NUMBER);
        }
      } else if (step === STEP.NUMBER) {
        // save campaign
        const body = {
          name,
          numEmails: number,
        };

        setIsSaving(true);
        const saveResp = await POST({ endpoint: "campaigns/save", body });

        navigate(`/campaign/${saveResp?.data?.emailCampaign?.id}`);
      }
    } else if (flow === FLOW.COPY) {
      // copying campaign
      if (step === STEP.NAME) {
        // save campaign
        const body = {
          campaignId: selectedCampaign?.id,
          name,
        };

        setIsSaving(true);
        const copyResp = await POST({ endpoint: "campaigns/copy", body });

        navigate(`/campaign/${copyResp?.data?.emailCampaign?.id}`);
      }
    }
  };

  const sendSequence = async (campaign: EmailCampaignTemplate) => {
    setIsSaving(true);
    const duplicateResp = await POST({
      endpoint: "campaigns/duplicate",
      body: { campaignId: campaign?.id },
    });

    if (duplicateResp?.error) {
      // todo
      setIsSaving(false);
    } else {
      navigate("/dashboard");
    }
  };

  const createNewCampaign = () => {
    setFlow(FLOW.NEW);
    setStep(STEP.NAME);
  };

  const selectCampaign = (campaign: EmailCampaignTemplate) => {
    setSelectedCampaign(campaign);
    setStep(STEP.NAME);
  };

  return (
    <>
      {step === STEP.CAMPAIGN && (
        <div className="container--maxWidth1100px marginTop48px">
          <div className="sequence_pageLabelAndButtonDiv">
            <p className="boldTextInBox boldTextInBox-bigger sequencePageLabel">
              Your sequences
            </p>
            <div className="newSequenceButtonDiv">
              <button className="mainButton" onClick={createNewCampaign}>
                + New Sequence
              </button>
            </div>
            
          </div>
          <div>
            {campaigns.map((campaign) => (
              <CampaignTemplate
                key={campaign?.id}
                campaign={campaign}
                sendSequence={() => sendSequence(campaign)}
                makeCopy={() => selectCampaign(campaign)}
              />
            ))}
          </div>
        </div>
      )}

      {step === STEP.NAME && (
        <div className="container--maxWidth1100px container--maxWidth1100px--paddingTop64px">
          <h1 className="header-newCampaignSteps">
            What should we name your new sequence?
          </h1>
          <p className="paragraph-newCampaignSteps">
            This won't be visible to anyone you send emails to, so name this
            anything you want.
          </p>
          <input
            className="hugeInput"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
      )}

      {step === STEP.NUMBER && (
        <div className="container--maxWidth1100px container--maxWidth1100px--paddingTop64px">
          <h1 className="header-newCampaignSteps">
            How many emails do you want to send?
          </h1>
          <p className="paragraph-newCampaignSteps">
            If you are sending to cold emails, we have found the best success
            with 4. However, if you aren't sure what you would write about for
            that many emails, feel free to do less.{" "}
          </p>
          <div
            style={{
              // todo: inline styles
              display: "flex",
              flexWrap: "wrap",
              marginLeft: -12,
              marginRight: -12,
            }}
          >
            <div
              className={"newCampaignValue"}
              style={
                number === 1 ? { backgroundColor: "#000", color: "#fff" } : {}
              }
              onClick={() => setNumber(1)}
            >
              <p>One</p>
            </div>
            <div
              className={"newCampaignValue"}
              style={
                number === 2 ? { backgroundColor: "#000", color: "#fff" } : {}
              }
              onClick={() => setNumber(2)}
            >
              <p>Two</p>
            </div>
            <div
              className={"newCampaignValue"}
              style={
                number === 3 ? { backgroundColor: "#000", color: "#fff" } : {}
              }
              onClick={() => setNumber(3)}
            >
              <p>Three</p>
            </div>
            <div
              className={"newCampaignValue"}
              style={
                number === 4 ? { backgroundColor: "#000", color: "#fff" } : {}
              }
              onClick={() => setNumber(4)}
            >
              <p>Four</p>
            </div>
          </div>
        </div>
      )}

      {step !== STEP.CAMPAIGN && (
        <div
          className="container--maxWidth1100px"
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }} // todo: inline styles
        >
          <SSCButton
            text="Oh yeah!"
            onClick={nextStep}
            isLoading={isSaving}
            className="mainButton"
          />
        </div>
      )}
    </>
  );
};

export default NewSequence;
