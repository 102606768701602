import * as React from "react";
import { MULTIPARTPOST, POST } from "../util/ApiUtils";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { getStartDate } from "../util/DateUtil";
import CsvHeaderSelect from "../components/CsvHeaderSelect";
import SequenceTimeSelect from "../components/SequenceTimeSelect";

const getMinDate = () => {
  const date = new Date();
  const hours = date.getHours();

  if (hours > 20) {
    const tomorrow = new Date();
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
  }

  return date;
};

const STEP = {
  UPLOAD: "upload",
  COLUMNS: "columns",
  SEND: "send",
};

const AddPeople = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();

  const [step, setStep] = useState<string>(STEP.UPLOAD);
  const [campaignId, setCampaignId] = useState<string>("");
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedHeaders, setSelectedHeaders] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [startDate, setStartDate] = useState<Date | null>(getMinDate());
  const [startTime, setStartTime] = useState<number>(6);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const emailCampaignId = state?.campaignId;

    if (!emailCampaignId) {
      navigate("/dashboard");
    }

    setCampaignId(emailCampaignId);

    const date = new Date();
    const hours = date.getHours() + 1;
    setStartTime(hours);
  }, []);

  const buttonClick = () => {
    inputRef?.current?.click();
  };

  const selectHeader = (h: string, index: number) => {
    const headerIndex = selectedHeaders.findIndex(
      (selectedHeader) => selectedHeader?.index === index,
    );

    const selectedHeader = {
      index,
      header: h,
    };

    if (headerIndex < 0) {
      setSelectedHeaders((prevState) => [...prevState, selectedHeader]);
    } else {
      setSelectedHeaders(
        selectedHeaders.map((header) =>
          header?.index === index ? selectedHeader : header,
        ),
      );
    }
  };

  // @ts-ignore
  const selectFile = ({ target: { files } }) => {
    if (!files || files[0] === null) return;

    setCsvFile(files[0]);

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e?.target?.result;
      if (!text || typeof text !== "string") {
        return;
      }

      const h = text
        .slice(0, text.indexOf("\n"))
        .split(",")
        .map((header) => header.trim());
      setHeaders(h);

      const numHeaders = h.length;
      const defaultSelectedHeaders: any[] = [];
      for (let i = 0; i < numHeaders; i++) {
        const defaultHeader = {
          index: i,
          header: "email",
        };

        defaultSelectedHeaders.push(defaultHeader);
      }

      setSelectedHeaders(defaultSelectedHeaders);
      setStep(STEP.COLUMNS);
    };

    reader.readAsText(files[0]);
  };

  const uploadFile = async () => {
    if (!csvFile) {
      return;
    }

    let data = new FormData();
    data.append("file", csvFile);
    data.append("headers", JSON.stringify(selectedHeaders));
    data.append("campaignId", campaignId);

    const fileUploadResp = await MULTIPARTPOST({
      endpoint: "campaigns/uploadCsv",
      body: data,
    });

    if (fileUploadResp?.data?.error) {
      // todo: error
    } else {
      setStep(STEP.SEND);
    }
  };

  const startCampaign = async () => {
    setError("");
    setIsLoading(true);
    POST({ endpoint: "campaigns/startCampaign", body: { campaignId } });
    navigate("/dashboard", { state: { startedCampaignId: campaignId } });
  };

  const scheduleForLater = async () => {
    if (!startDate) {
      setError("No start date selected");
      return;
    }

    if (!startTime) {
      setError("No start time selected");
      return;
    }

    setIsScheduling(true);
    setError("");

    const month = startDate.getUTCMonth() + 1;
    const day = startDate.getDate();
    const year = startDate.getUTCFullYear();

    const date = getStartDate(month, day, year, startTime);

    const scheduleResp: any = await POST({
      endpoint: "campaigns/schedule",
      body: { campaignId, date },
    });

    if (scheduleResp?.data?.error) {
      setError(scheduleResp?.data?.errorMessage);
      setIsScheduling(false);
    } else {
      navigate("/dashboard");
    }
  };

  if (step === STEP.COLUMNS) {
    return (
      <CsvHeaderSelect
        headers={headers}
        onSelectHeader={selectHeader}
        onUpload={uploadFile}
        uploadText="Next Step"
      />
    );
  } else if (step === STEP.SEND) {
    return (
      <SequenceTimeSelect
        error={error}
        onRightNow={startCampaign}
        isLoading={isLoading}
        onScheduleForLater={scheduleForLater}
        isScheduling={isScheduling}
      />
    );
  }

  return (
    <div className="container--maxWidth1100px container--maxWidth1100px--height80vh--centerVertAndHorz">
      <div className="centerText">
        <h1>Upload a CSV</h1>
        <button className="mainButton" onClick={buttonClick}>
          Upload CSV
        </button>
        <input
          type="file"
          accept="text/csv"
          hidden
          ref={inputRef}
          onChange={selectFile}
        />
      </div>
    </div>
  );
};

export default AddPeople;
