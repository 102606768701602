import React from "react";
// @ts-ignore
import { ReactComponent as Delete } from "../images/delete.svg";
// @ts-ignore
import { ReactComponent as Edit } from "../images/edit.svg";
import { Person } from "../types/Person";

interface PeopleProps {
  people: Person[];
  onEdit: (person: Person) => void;
  onDelete: (person: Person) => void;
}

const Table = ({ people, onEdit, onDelete }: PeopleProps) => {
  return (
    <>
      <table className="mainTable">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Title</th>
            <th>Company Name</th>
            <th>Website</th>
            <th>Email</th>
            <th>LinkedIn</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {people?.map((person, index) => (
            <tr key={index} className="borderBottom">
              <td>{person.firstName}</td>
              <td>{person.lastName}</td>
              <td>{person?.title}</td>
              <td>{person?.companyName}</td>
              <td className="column-website">
                <a href={person?.website}>{person?.website}</a>
              </td>
              <td className="column-email">{person.email}</td>
              <td className="column-linkedIn">
                <a href={person?.linkedIn}>{person?.linkedIn}</a>
              </td>
              <td>
                <div className="peopleTable_icons">
                  <div
                    className="peopleTable_iconDiv"
                    onClick={() => onEdit(person)}
                  >
                    <Edit style={{ width: 24, height: 24 }} />
                  </div>
                  <div
                    className="peopleTable_iconDiv"
                    onClick={() => onDelete(person)}
                  >
                    <Delete style={{ width: 24, height: 24 }} />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
