import React from "react";
import SSCButton from "../components/SSCButton";
import { POST } from "../util/ApiUtils";

const PAYMENT_FREQUENCY = {
  MONTHLY: "monthly",
  ANNUAL: "annual",
  LIFETIME: "lifetime"
};

const PaymentOptions = () => {
  const redirectToPayment = async (frequency: string) => {
    const resp = await POST({
      endpoint: "payment/createCheckoutSession",
      body: { frequency },
    });

    if (resp?.data?.redirect) {
      window.location.href = resp?.data?.redirect;
    }
  };

  return (
    <>
      <div className="container--maxWidth1100px">
        <h2 className="font-pickAPlan">Lets pick a plan</h2>

        <div className="planChoices">
          <div className="halfSide">
            <div className="box-monthlyPlan">
              <div className="padding24px">
                <h3 className="font-planLabel">Monthly Plan</h3>
                <h1 className="font-price marginBottom24px">
                  $20<span className="font-per">/mo</span>
                </h1>

                <h4 className="font-whatYouGet">What you get</h4>
                <h3 className="marginBottom12px">
                  Fully Automated Sequencing Tool
                </h3>
                <h3 className="marginBottom12px">
                  Sequence Review by Our Team
                </h3>
                <h3 className="marginBottom12px">
                  Access to our Members Only Slack Group
                </h3>
                <br></br>
                <br></br>
                <br></br>
              </div>

              <div className="div-buttonBottom">
                <SSCButton
                  text="Sign Up for the Monthly Plan"
                  onClick={() => redirectToPayment(PAYMENT_FREQUENCY.MONTHLY)}
                  className="mainButton"
                />
              </div>
            </div>
          </div>
          {/* <div className="halfSide">
            <div className="box-annualPlan">
              <div className="padding24px">
                <h3 className="font-planLabel">Annual Plan</h3>
                <h1 className="font-price marginBottom24px">
                  $200<span className="font-per">/yr</span>
                </h1>

                <h4 className="font-whatYouGet">What you get</h4>
                <h3 className="marginBottom12px">
                  Customized T-Shirt in Your Size
                </h3>
                <h3 className="marginBottom12px">Two months free!</h3>
                <h3 className="marginBottom12px">
                  Fully Automated Sequencing Tool
                </h3>
                <h3 className="marginBottom12px">
                  Sequence Review by Our Team
                </h3>
                <h3 className="marginBottom12px">
                  Access to our Members Only Slack Group
                </h3>
                <br></br>
                <br></br>
                <br></br>
              </div>

              <div className="div-buttonBottom">
                <SSCButton
                  text="Sign Up for the Annual Plan"
                  onClick={() => redirectToPayment(PAYMENT_FREQUENCY.ANNUAL)}
                  className="mainButton mainButton__white"
                />
              </div>
            </div>
          </div> */}
          <div className="halfSide">
            <div className="box-annualPlan">
              <div className="padding24px">
                <h3 className="font-planLabel">Lifetime Plan</h3>
                <h1 className="font-price marginBottom24px">
                  $200<span className="font-per">/one time</span>
                </h1>

                <h4 className="font-whatYouGet">What you get</h4>
                
                <h3 className="marginBottom12px">Freaking lifetime!</h3>
                <h3 className="marginBottom12px">
                  Fully Automated Sequencing Tool
                </h3>
                <h3 className="marginBottom12px">
                  Sequence Review by Our Team
                </h3>
                <h3 className="marginBottom12px">
                  Access to our Members Only Slack Group
                </h3>
                <h3 className="marginBottom12px">
                  1:1 training with our founder
                </h3>
                <br></br>
                <br></br>
                <br></br>
              </div>

              <div className="div-buttonBottom">
                <SSCButton
                  text="Sign Up for the Lifetime Plan"
                  onClick={() => redirectToPayment(PAYMENT_FREQUENCY.LIFETIME)}
                  className="mainButton mainButton__white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOptions;
