import * as React from "react";
import "./App.css";
import "./index.css";
import Header from "./components/Header";
import Router from "./components/Router";
import AuthProvider from "./auth/AuthProvider";

const App = () => {
  return (
    <AuthProvider>
      <Header />
      <Router />
    </AuthProvider>
  );
}

export default App;
