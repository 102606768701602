import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Help from "../pages/Help";
import RequireAuth from "../auth/RequireAuth";
import Dashboard from "../pages/Dashboard";
import NewSequence from "../pages/NewSequence";
import EmailCampaign from "../pages/EmailCampaign";
import AddPeople from "../pages/AddPeople";
import PaymentConfirmation from "../pages/PaymentConfirmation";
import OAuthRedirect from "../pages/OAuthRedirect";
import PaymentOptions from "../pages/PaymentOptions";
import PersonalizationPage from "../pages/PersonalizationPage";
import People from "../pages/People";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/help" element={<Help />} />
      <Route path="/oauth2/redirect" element={<OAuthRedirect />} />
      <Route
        path="/confirmation"
        element={
          <RequireAuth>
            <PaymentConfirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />

      <Route
        path="/people"
        element={
          <RequireAuth>
            <People />
          </RequireAuth>
        }
      />

      <Route
        path="/personalize"
        element={
          <RequireAuth>
            <PersonalizationPage />
          </RequireAuth>
        }
      />

      <Route
        path="/newSequence"
        element={
          <RequireAuth>
            <NewSequence />
          </RequireAuth>
        }
      />
      <Route
        path="/campaign/:campaignId"
        element={
          <RequireAuth>
            <EmailCampaign />
          </RequireAuth>
        }
      />
      <Route
        path="/addPeople"
        element={
          <RequireAuth>
            <AddPeople />
          </RequireAuth>
        }
      />
      <Route
        path="/addPeople"
        element={
          <RequireAuth>
            <AddPeople />
          </RequireAuth>
        }
      />
      <Route
        path="/paymentOptions"
        element={
          <RequireAuth>
            <PaymentOptions />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Router;
