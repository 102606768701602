import { useEffect, useState } from "react";
import { GET } from "../util/ApiUtils";

interface PaginationProps {
  page: number;
  endpoint: string;
  data?: object;
  dependencies?: any[];
}

export const usePagination = <T>({
  page,
  endpoint,
  data = {},
  dependencies = [],
}: PaginationProps) => {
  const [list, setList] = useState<T[]>([]);
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    load();
  }, [page, ...dependencies]);

  const load = async () => {
    const loadResp = await GET({
      endpoint: endpoint,
      params: { page, ...data },
    });

    setList(loadResp?.data?.list);
    setNumPages(loadResp?.data?.numPages);
  };

  const reload = async () => {
    await load();
  };

  return { list, numPages, reload };
};
