import React from "react";
import { Person } from "../types/Person";

interface EmailEditorProps {
  emailBody: string;
  lead: Person;
  paragraphText: string;
  setParagraphText: (text: string) => void;
  linkText: string;
  setLinkText: (text: string) => void;
}

const EmailEditor = ({
  emailBody = "",
  lead,
  paragraphText,
  setParagraphText,
  linkText,
  setLinkText,
}: EmailEditorProps) => {
  const parts = emailBody.split(/(\[%\w+%])/);

  let accumulatedText = "";
  const elements = [];

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    if (part === "[%paragraph%]" || part === "[%link%]") {
      if (accumulatedText.trim()) {
        elements.push(
          <p key={`accumulated-${i}`} style={{ whiteSpace: "pre-wrap" }}>
            {accumulatedText}
          </p>,
        );
        accumulatedText = "";
      }

      if (part === "[%paragraph%]") {
        elements.push(
          <div className="emailCustomization_div">
            <div className="emailCustomization_header">
              <p>PARAGRAPH</p>
            </div>
            <textarea
              className="emailCustomization_textarea"
              placeholder="Type your paragraph here"
              value={paragraphText}
              onChange={(e) => setParagraphText(e?.target?.value)}
            />
          </div>,
        );
      } else {
        elements.push(
          <div className="emailCustomization_div">
            <div className="emailCustomization_header">
              <p>LINK</p>
            </div>
            <input
              className="emailCustomization_textInput"
              placeholder="Paste your link here"
              value={linkText}
              onChange={(e) => setLinkText(e?.target?.value)}
            />
          </div>,
        );
      }

      if (parts[i + 1] && parts[i + 1].startsWith("\n")) {
        parts[i + 1] = parts[i + 1].substring(1);
      }
    } else {
      accumulatedText += part;
    }
  }

  if (accumulatedText.trim()) {
    elements.push(
      <p key="accumulated-final" style={{ whiteSpace: "pre-wrap" }}>
        {accumulatedText}
      </p>,
    );
  }

  return <>{elements}</>;
};

export default EmailEditor;
