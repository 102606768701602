import React from "react";
import {Navigate, useLocation, useSearchParams} from "react-router-dom";

const OAuthRedirect = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams()

  const authenticated = searchParams.get('authenticated');
  const error = searchParams.get('error');

  // todo get current session and sign in user?

  if(authenticated) {
    return <Navigate to='/dashboard' state={{ from: location }} replace />
  }
  else {
    return <Navigate to='/login' state={{ from: location, error }} replace />
  }
}

export default OAuthRedirect;
