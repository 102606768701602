import * as React from "react";

interface HeaderSelectProps {
  headers: string[];
  onSelectHeader: (header: string, index: number) => void;
  onUpload: () => void;
  uploadText?: string;
}

const CsvHeaderSelect = ({
  headers,
  onSelectHeader,
  onUpload,
  uploadText = "Upload",
}: HeaderSelectProps) => {
  return (
    <div className="container--maxWidth900px container--maxWidth1100px--paddingTop64px container--paddingBottom64px">
      <h1>Configure your CSV</h1>
      <p className="marginBottom48px">
        In this table, you can set the columns of your CSV to match to fields
        you can use in your emails.
      </p>
      <div className="csvTable_container">
        {headers?.map((header, index) => {
          return (
            <div className="csvTable_innerDiv" key={index}>
              <div>
                <p className="textLabel">Field</p>
                <p className="csvTable_field">{header}</p>
              </div>
              <div>
                <p className="textLabel">Column</p>
                <select
                  className="csvTable_select "
                  onChange={(e) => onSelectHeader(e?.target?.value, index)}
                >
                  <option value="email">Email</option>
                  <option value="firstName">First Name</option>
                  <option value="lastName">Last Name</option>
                  <option value="title">Title</option>
                  <option value="companyName">Company Name</option>
                  <option value="website">Website</option>
                  <option value="linkedIn">LinkedIn</option>
                  <option value="link">Link</option>
                </select>
              </div>
            </div>
          );
        })}
      </div>
      <div className="buttonDiv--rightSide ">
        <button className="mainButton marginTop24px" onClick={onUpload}>
          {uploadText}
        </button>
      </div>
    </div>
  );
};

export default CsvHeaderSelect;
