import React from 'react';
import {createContext, ReactNode, useContext, useState} from 'react';

interface AuthContextType {
  user: any;
  signIn: (user: string, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
}

const auth = {
  isAuthenticated: false,
  signIn(callback: any) {
    auth.isAuthenticated = true;
    if(callback){
      callback();
    }
  },
  signOut(callback: any) {
    auth.isAuthenticated = false;
    if(callback)
      callback();
  }
};

const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
  let [user, setUser] = useState<any>(null);

  const signIn = (user: any, callBack: VoidFunction) => {
    return auth.signIn(() => {
      setUser(user);
      if(callBack)
      {
        callBack();
      }
    });
  };

  const signOut = (callBack: VoidFunction) => {
    return auth.signOut(() => {
      setUser(null);
      if(callBack)
      {
        callBack();
      }
    });
  };

  let value = { user, signIn, signOut };

  return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
}

export default AuthProvider;
