import * as React from "react";
import { useEffect, useState } from "react";

interface InputProps {
  num: number;
  onChange: (num: number) => void;
  disabled: boolean;
}

const DayInput = ({ num, onChange, disabled = false }: InputProps) => {
  const [days, setDays] = useState<string>("1");

  useEffect(() => {
    setDays(num.toString());
  }, [num]);

  useEffect(() => {
    if (isNaN(parseInt(days)) || num === parseInt(days)) {
      return;
    }

    const timeout = setTimeout(() => {
      onChange(parseInt(days));
    }, 800);

    return () => clearTimeout(timeout);
  }, [days]);

  const updateDays = (val: string) => {
    if (val && isNaN(parseInt(val))) {
      return;
    }

    if (parseInt(val) < 1) {
      return;
    }

    setDays(val);
  };

  const backgroundColor = disabled ? "#808080" : "#000";

  return (
    <div
      className="dayInput_div"
      style={{
        backgroundColor,
      }}
    >
      <input
        className="dayInput_input  fontSize--20px"
        type="text"
        style={{
          backgroundColor,
        }}
        value={days}
        onChange={(e) => updateDays(e?.target?.value)}
        disabled={disabled}
      />
      <p>{`Day${parseInt(days) !== 1 ? "s" : ""}`}</p>
    </div>
  );
};

export default DayInput;
