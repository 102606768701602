import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GET, POST } from "../util/ApiUtils";
import ReactModal from "react-modal";
import DayInput from "../components/DayInput";
import SSCButton from "../components/SSCButton";
import SSCCheckbox from "../components/SSCCheckbox";

const EmailCampaign = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [emailCampaign, setEmailCampaign] = useState<any>();
  const [emails, setEmails] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showWritingHelpModal, setShowWritingHelpModal] =
    useState<boolean>(false);

  const [editEmailIndex, setEditEmailIndex] = useState<number>();
  const [selectedInput, setSelectedInput] = useState<"subject" | "body">(
    "subject"
  );
  const [cursorLocation, setCursorLocation] = useState<number | null>(null);

  const [isSavingDays, setIsSavingDays] = useState<boolean>(false);
  const [isSendingPreview, setIsSendingPreview] = useState<boolean>(false);
  const [isSavingEmail, setIsSavingEmail] = useState<boolean>(false);

  const [subject, setSubject] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const [isPreviewAlertBarActive, setIsPreviewAlertBarActive] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const campaignResp = await GET({
      endpoint: "campaigns/id",
      params: { id: params?.campaignId },
    });

    setEmailCampaign(campaignResp?.data?.emailCampaign);
    setEmails(campaignResp?.data?.emailCampaign?.emails);
  };

  const editEmail = (index: number) => {
    setEditEmailIndex(index);
    setShowModal(true);
  };

  const updateDaysToWait = (emailId: string, num: number) => {
    if (!emailId) {
      return;
    }
    const daysToWait = isNaN(num) ? 0 : num;

    setEmails(
      emails.map((em) => (em?.id === emailId ? { ...em, daysToWait } : em))
    );
  };

  const toggleSendAsReply = (emailId: string) => {
    setEmails(
      emails.map((em) =>
        emailId === em?.id ? { ...em, sendAsReply: !em?.sendAsReply } : em
      )
    );
  };

  const addEmail = async () => {
    const addEmailResp = await POST({
      endpoint: "campaigns/addEmail",
      body: { id: params?.campaignId },
    });

    const updatedCampaign = addEmailResp?.data?.emailCampaign;
    setEmailCampaign(updatedCampaign);
  };

  const renderEmails = () => {
    let renderEmails = [];
    for (let i = 0; i < emailCampaign?.numEmails; i++) {
      const email = emails.find((em) => em?.index === i);

      renderEmails.push(
        <div key={i} className="marginBottom24px">
          {i > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginRight: 5, fontSize: 18 }}>
                Wait for
              </p>
              <DayInput
                num={email ? email?.daysToWait : 1}
                onChange={(num) => updateDaysToWait(email?.id, num)}
                disabled={!email}
              />
            </div>
          )}
          <div>
            <p className="labelInBox marginBottom8px">Email #{i + 1}</p>
            <div className="emailContainer">
              <div className="emailContainer_text">
                <p className="labelInBox">Subject Line</p>
                <p className="boldTextInBox">
                  {email?.subject || `Email #${i + 1}`}
                </p>
                {i > 0 && email && (
                  // todo: Rob styles
                  <div style={{ marginTop: 10 }}>
                    <SSCCheckbox
                      label="Send as reply"
                      id={`email-${i}-reply`}
                      checked={email?.sendAsReply || false}
                      onClick={() => toggleSendAsReply(email?.id)}
                    />
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <button
                  className="mainButton marginBottom16px"
                  onClick={() => editEmail(i)}
                >
                  {email ? "Make a change" : "Write"}
                </button>
                <SSCButton
                  text="Send Preview"
                  onClick={() => previewEmail(email?.id)}
                  isLoading={isSendingPreview}
                  className="mainButton opacity50"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return renderEmails;
  };

  const previewEmail = async (emailId: string) => {
    setIsSendingPreview(true);
    await POST({ endpoint: "campaigns/previewEmail", body: { emailId } });
    setIsSendingPreview(false);
    setIsPreviewAlertBarActive(true);
  };

  const closeEmailPreviewAlertBar = async () => {
    setIsPreviewAlertBarActive(false);
  };

  const onModalOpen = () => {
    const email = emails.find((em) => em?.index === editEmailIndex);
    setSubject(email?.subject);
    setBody(email?.body);
  };

  const onModalClose = () => {
    setSubject("");
    setBody("");
  };

  const addKeyword = (keyword: string) => {
    const newKeyword = `[%${keyword}%]`;

    if (selectedInput === "subject") {
      let newSubject = `${subject || ""}${newKeyword}`;
      if (cursorLocation) {
        newSubject = [
          subject.slice(0, cursorLocation),
          newKeyword,
          subject.slice(cursorLocation),
        ].join("");
      }
      setSubject(newSubject);
    } else if (selectedInput === "body") {
      let newBody = `${body || ""}${newKeyword}`;
      if (cursorLocation) {
        newBody = [
          body.slice(0, cursorLocation),
          newKeyword,
          body.slice(cursorLocation),
        ].join("");
      }
      setBody(newBody);
    }
  };

  const saveEmail = async () => {
    const email = {
      index: editEmailIndex,
      subject,
      body,
    };

    setIsSavingEmail(true);
    const emailResp = await POST({
      endpoint: "campaigns/saveEmail",
      body: { emailCampaignId: emailCampaign?.id, email },
    });
    const savedEmail = emailResp?.data?.email;

    const index = emails.findIndex((em) => em?.index === savedEmail?.index);
    if (index < 0) {
      emails.push(savedEmail);
    } else {
      setEmails(
        emails.map((em) => (em?.index === savedEmail?.index ? savedEmail : em))
      );
    }

    setIsSavingEmail(false);
    setShowModal(false);
  };

  const getKeywordOptions = () => {
    if (selectedInput === "subject") {
      return (
        <>
          <option value="value">Insert Value</option>
          <option value="firstName">First Name</option>
          <option value="companyName">Company Name</option>
        </>
      );
    } else if (selectedInput === "body") {
      return (
        <>
          <option value="value">Insert Value</option>
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="companyName">Company Name</option>
          <option value="link">Link</option>
          <option value="paragraph">Paragraph</option>
        </>
      );
    }
  };

  const saveDaysToWait = async () => {
    setIsSavingDays(true);
    await POST({
      endpoint: "campaigns/updateCampaign",
      body: { campaignId: emailCampaign?.id, emails },
    });
    navigate("/dashboard");
  };

  return (
    <>
      <div
        className="topAlertBar"
        style={{ display: isPreviewAlertBarActive ? "flex" : "none" }}
      >
        <h3>A preview of this email has been sent to your email.</h3>
        <button
          className="closeEmailPreview"
          onClick={closeEmailPreviewAlertBar}
        >
          Close
        </button>
      </div>

      <div className="">
        <div className="pageHeaderContainer">
          <div>
            <p className="labelInBox">Sequence Name</p>
            <p className="boldTextInBox">{emailCampaign?.name}</p>
          </div>
        </div>

        <div className="container--maxWidth1100px ">
          {renderEmails()}

          {emailCampaign?.numEmails <= 3 && (
            <div>
              <SSCButton
                text="+ Add Email"
                className="mainButton"
                onClick={addEmail}
              />
            </div>
          )}
        </div>

        <div className="emailSetupPage-footer">
          <button
            onClick={() => setShowWritingHelpModal(true)}
            className="link-helpMe"
          >
            Get help
          </button>
          <SSCButton
            text="Save Sequence"
            onClick={saveDaysToWait}
            isLoading={isSavingDays}
            className="mainButton"
          />
        </div>
      </div>

      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        onAfterOpen={onModalOpen}
        onAfterClose={onModalClose}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 24px",
          },
          content: {
            width: "100%",
            maxWidth: "480px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            position: "relative",
          },
        }}
        ariaHideApp={false}
      >
        <div>
          <p
            className="closeButtonTopRight"
            onClick={() => setShowModal(false)}
          >
            Close
          </p>
          <div className="paddingTop32px">
            <p className="labelInBox marginBottom4px">Subject</p>
            <input
              className="inputBox marginBottom16px width100Percent"
              type="text"
              value={subject}
              onChange={(e) => setSubject(e?.target?.value)}
              onClick={() => setSelectedInput("subject")}
              onFocus={() => setSelectedInput("subject")}
              onBlur={(e) => setCursorLocation(e?.target?.selectionStart)}
            />
          </div>

          <div>
            <p className="labelInBox marginBottom4px">Body</p>
            <textarea
              className="textArea width100Percent"
              rows={6}
              value={body}
              onChange={(e) => setBody(e?.target?.value)}
              onClick={() => setSelectedInput("body")}
              onFocus={() => setSelectedInput("body")}
              onBlur={(e) => setCursorLocation(e?.target?.selectionStart)}
            />
          </div>
        </div>

        <div className="header">
          <select
            className="selectInModal"
            value="value"
            onChange={(e) => addKeyword(e?.target?.value)}
          >
            {getKeywordOptions()}
          </select>

          <div className="modal--buttonDiv modal--buttonDiv--left">
            <p className="closeButton" onClick={() => setShowModal(false)}>
              Cancel
            </p>
            <SSCButton
              text="Save"
              onClick={saveEmail}
              isLoading={isSavingEmail}
              className="mainButton"
            />
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showWritingHelpModal}
        onRequestClose={() => setShowWritingHelpModal(false)}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 32px",
          },
          content: {
            width: "100%",
            maxWidth: "400px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            position: "relative",
          },
        }}
        ariaHideApp={false}
      >
        <p
          className="closeButtonTopRight"
          onClick={() => setShowWritingHelpModal(false)}
        >
          Close
        </p>

        <h3>
          We are working on a video course to help you learn at your own pace.
          However, this is gonna take some work.{" "}
        </h3>
        <p>
          In the meantime, you can talk to our founder Rob Metcalf. In his own
          words, "I will do whatever it takes to get you selling!" <br></br>
          <br></br>
          However, this is only for people on a lifetime plan.
          <br></br>
          <br></br>If you are on a lifetime plan, reach out to Rob on Slack. If
          you aren't on the lifetime plan, we would love to have you! You save
          money ($200 for life instead of $20 per month) and get 1:1 help from
          our team.
          <br></br>
          <br></br>
          If you want to upgrade, go to your profile page.
        </p>
      </ReactModal>
    </>
  );
};

export default EmailCampaign;
